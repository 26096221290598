<template>
<el-dialog v-model="visible" title="开通会员" class="open-vip-box">
	<!-- 会员名称 -->
	<div class="content-box vip-price-box flex-row">
		<div class="vip-item" :class="{active:aIndex==index}" @click="menuBtn(item, index)" v-for="(item,index) in vipBoxList">
			<div class="vip-title">{{item.commodityName}}</div>
			<div class="vip-content">{{item.commodityPrice }}元</div>
		</div>
	</div>
	<div class="pay-content-box">
		<div class="pay-box flex-row" 
			v-for="(item,index) in payList"
			@click="payBtn(index)" :class="{active:payIndex==index}">
			<img :src="item.icon" class="icon-pay" alt="">
			<span>{{item.payName}}</span>
		</div>
	</div>
	<div class="dialog-footer">
		<el-button class="btn" @click="visible=false">取消</el-button>
		<el-button class="btn confirm-btn" @click="openVipBtn" type="primary">确定</el-button>
	</div>
</el-dialog>
	<!-- 微信扫码支付 -->
	<el-dialog title="微信扫码支付" v-model="wxVisible">
		<div class="weixin-title">请打开手机微信扫码支付</div>
		<canvas ref="openCanvas" style="width:160px;height:160px;margin:0 auto;display:block;"></canvas>
		<div slot="footer" class="flex-row flex-center dialog-footer-box">
			<el-button @click="wxPayOkBtn" class="footer-btn" >取消</el-button>
			<el-button @click="wxPayOkBtn" class="footer-btn" type="primary">确定</el-button>
		</div>
	</el-dialog>


</template>

<script>
	import {userInfo, commodityList, pay, payConfigList,getOrderById } from '@/http/http.js';
	import QRCode from 'qrcode'
	export default {
		data(){
			return {
				visible:false,
				aIndex:0,
				vipBoxList:[],
				payList:[],
				vipItem:{},
				payItem:{},
				payIndex:0,
				wxVisible:false,
				payTimer:null,
				payOrderId:'',
			}
		},
		created(){
			
		},
		mounted(){
			this.getData();
			this.getPayData();
		},
		methods: {
			getData(){
				commodityList().then(res=>{
					this.vipBoxList = [...res.data.VIP];
				})
			},
			open(){
				this.visible = true;
			},
			payBtn(index){
				this.payIndex = index;
			},
			getPayData(){
				payConfigList({type:6}).then(res=>{
					this.payList = res.data;
				})
			},
			menuBtn(item, index){
				this.vipItem = item;
				this.aIndex = index;
			},
			checkOrderPay(){
				if(this.payTimer){
					clearInterval(this.payTimer)
				}
				this.payTimer = setInterval(()=>{
					getOrderById({orderId:this.payOrderId}).then(res=>{
						let type = res.data?.type??1;
						if(type==2){
							ElMessage.success("支付成功");
							this.wxPayOkBtn()
						}
					})
				},1500)
				
			},
			wxPayOkBtn(){
				if(this.payTimer){
					clearInterval(this.payTimer)
				}
				this.wxVisible = false;
			},
			openVipBtn(){
				let param = {
					commodityId: this.vipBoxList[this.aIndex].id,
					payId: this.payList[this.payIndex].id
				}
				let payItem = this.payList[this.payIndex];
				pay(param).then(res=>{
					this.payOrderId = res.data.OrderId
					if(res.data.type === 6){
						this.wxVisible = true;
						let opts = {
						  errorCorrectionLevel: 'H',
						  type: 'image/png',
						  quality: 0.3,
						  width: 165,
						  height: 165,
						  color: {
						    dark: '#333333',
						    light: '#fff'
						  }
						}
						this.$nextTick(()=>{
							QRCode.toCanvas(this.$refs.openCanvas, res.data.payLink, opts)
						})
						this.checkOrderPay()
					}else{
						window.open(res.data.payLink,'_blank');
					}
				})
			}
		},
	}
</script>

<style scoped lang="scss">
.weixin-title{
		text-align:center;
		color:#333;
		margin-top:12px;
		margin-bottom:12px;
	}
.open-vip-box{
	position:relative;
	
	.vip-price-box{
		margin-top:35px;
		
		.vip-item{
			width:200px;
			border:1px solid #EFEFEF;
			padding:20px 30px;
			border-radius: 10px;
			text-align: center;
			margin-left:20px;
			margin-right:20rpx;
			cursor:pointer;
			&:hover{
				border-color:$blue;
				color:$blue;
			}
			&:first-child{
				margin-left:0;
			}
			&:last-child{
				margin-right:0;
			}
			&.active{
				background: linear-gradient(135deg,$blue,#90B8F8);
				color:#FFF;
			}
		}
	}
	.pay-content-box{
		margin-top:12px;
	}
	.pay-box{
		padding:16px 12px;
		display: flex;
		align-items: center;
		cursor: pointer;
		border-radius: 5px;
		
		&.active{
			background-color: rgba($blue,.1);
			color:$blue;
		}
		.icon-pay{
			width:30px;
			height:30px;
			margin-right:12px;
		}
	}
	
	.dialog-footer{
		text-align:center;
		margin-top:20px;
		.btn{
			height:48px;
			width:120px;
			border-radius: 12px;
		}
		.confirm-btn{
			background-color: $blue;
			color:#FFF;
			margin-left:32px;
		}
	}
}	
</style>