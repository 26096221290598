import { defineStore } from 'pinia'

export const globalData = defineStore({
  id: 'globalData',
  state: () => ({
    count: 0,
    menuIndex: localStorage.getItem('menuIndex') || 1,
    userInfo: localStorage.getItem('userInfo') || {},
    loadingInstance: null,
    isCollapse: false,
    token: localStorage.getItem('token'),
    loginVisible: false,
    // 1-不显示，2-显示
    activeBoxVisible: localStorage.activeBoxVisible || '1',
  }),
  getters: {
    getUserInfo: (state) => state.userInfo,
  },
  actions: {
    setUserInfo(userInfo) {
      localStorage.userInfo = userInfo
      this.userInfo = userInfo;
    },
    setMenuIndex(index) {
      localStorage.menuIndex = index;
      this.menuIndex = index;
    },
    showLoading(index = {}) {
      // ElLoading.service返回的是同一个实例
      const options = Object.assign({
        text: '加载中',
        fullscreen: false,
        background: 'transparent'
      }, index);
      this.loadingInstance = ElLoading.service(options);
    },
    hideLoading() {
      if (this.loadingInstance) {
        this.loadingInstance.close()
      }
    },
    setCollapse(val) {
      this.isCollapse = val;
    },
    changeMenuCollapse() {
      this.isCollapse = !this.isCollapse;
    },
    setToken(token) {
      localStorage.token = token;
      this.token = token
    },
    setLoginVisible(visible) {
      this.loginVisible = visible;
    },
    setActiveBoxVisible(value) {
      localStorage.setItem('activeBoxVisible', value)
      this.activeBoxVisible = value;
    }
  },
})