<template>
  <el-container>
    <el-aside :width="isCollapse ? '80px' : '170px'" class="menu-box">
      <div class="menu-title flex-row flex-center font-20">
        <img src="https://netefox.oss-cn-shenzhen.aliyuncs.com/efox-chat-gpt/netefox.png"
          class="logo-img" alt="">
      </div>
      <el-scrollbar class="page-scroll">
        <el-menu :default-active="`${menuIndex}`" :collapse="isCollapse">
          <el-menu-item @click="menuBtn(item, index)" v-for="(item, index) in menuList" :key="index"
            :index="index.toString()">
            <img :src="getImageUrl(index === menuIndex ? item.imgOn : item.img)" class="icon-tabbar" alt="" />
            <template #title>{{ item.name }}</template>
          </el-menu-item>
        </el-menu>
      </el-scrollbar>
      <div @click="changeCollapse" class="collapse pointer" :style="{ left: isCollapse ? '80px' : '170px' }">
        <el-icon size="12" color="#fff">
          <i-ep-arrow-right v-if="isCollapse" />
          <i-ep-arrow-left v-else />
        </el-icon>
      </div>
    </el-aside>
    <el-main class="right-box px-20 py-12">
      <navTop></navTop>
      <div class="mt-12">
        <router-view></router-view>
      </div>
    </el-main>
  </el-container>
</template>

<script >
import { getImageUrl } from '@/utils/utils.js'
import navTop from '@/components/navTop/navTop.vue'
import { globalData } from '@/store/store.js'
import { mapActions, mapState } from 'pinia';

export default {
  data() {
    return {
      menuList: [
        {
          name: '快速问答',
          url: '/home/home',
          img: 'index.png',
          imgOn: 'indexon.png',
        },
        {
          name: '智能绘画',
          url: '/home/paint',
          img: 'aihh.png',
          imgOn: 'aihhon.png',
        },
        // {
        //   name: 'chatPDF',
        //   url: '/home/chatpdf',
        //   img: 'pdf.png',
        //   imgOn: 'pdfon.png',
        // },
        {
          name: '短视频脚本',
          url: '/home/shortVideo',
          img: 'shortVideo.png',
          imgOn: 'shortVideoOn.png',
        },
        // {
        //   name: 'AI全家桶',
        //   url: '/home/issureTmp',
        //   img: 'AIqjt.png',
        //   imgOn: 'AIqjton.png',
        // },
        {
          name: '我的提问',
          url: '/home/issureLog',
          img: 'wdtwLog.png',
          imgOn: 'wdtwLogon.png',
        },
        // {
        //   name: '我的图库', url: '/home/myPaint',
        //   img: 'wdtk.png',
        //   imgOn: 'wdtkon.png',
        // },
        {
          name: '问答流水', url: '/home/coinLog',
          img: 'wdls.png',
          imgOn: 'wdlson.png',
        },
        {
          name: '快速充值', url: '/home/recharge',
          img: 'kscz.png',
          imgOn: 'ksczon.png',
        },
        {
          name: '分销中心', url: '/home/user',
          img: 'fxzx.png',
          imgOn: 'fxzxon.png',
        },
      ],
      getImageUrl,
    }
  },
  watch: {
    '$route.path'(newValue, oldValue) {
      const curMenuIndex = this.menuList.findIndex(item => item.url === newValue)
      if (curMenuIndex) {
        this.setMenuIndex(curMenuIndex)
      }
    }
  },
  computed: {
    ...mapState(globalData, ['isCollapse', 'menuIndex'])
  },
  methods: {
    ...mapActions(globalData, ['changeMenuCollapse', 'setMenuIndex']),
    changeCollapse() {
      this.changeMenuCollapse()
    },
    menuBtn(item, index) {
      this.setMenuIndex(index)
      if (item.url) {
        this.$router.push({ path: item.url })
      }
    }
  },
  created() {
    // 根据url设置初始选中菜单
    const index = this.menuList.findIndex(menu => window.location.pathname.includes(menu.url));
    if (index !== -1) {
      this.setMenuIndex(index);
    }
  }
}
</script>

<style scoped lang="scss">
.menu-box {
  padding-top: 20px;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
  /* 加过渡给侧边导航*/
  transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -moz-transition: width 0.25s;
  -webkit-transition: width 0.25s;
  -o-transition: width 0.25s;

  &::-webkit-scrollbar {
    display: none;
  }

  /*加快侧边栏文字消失的速度*/
  .el-menu {
    transition: all 10ms;
  }

  .page-scroll {
    height: calc(100% - 60px);
  }

  .page-scroll .el-scrollbar__wrap {
    overflow-x: hidden;
  }

  .menu-title {
    color: $theme-color;
    margin-bottom: 20px;
  }

  .collapse {
    position: fixed;
    background-color: rgba(#606266, .2);
    height: 46px;
    width: 12px;
    line-height: 46px;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    top: 50vh;

    &:hover {
      background-color: rgba(#606266, .3);
    }
  }

  .logo-img {
    width: 75px;
  }

  .el-menu {
    border-right: none !important;
  }

  .el-menu-item {
    .icon-tabbar {
      width: 22px;
      height: 22px;
      margin-right: 12px;
    }
  }
}

.right-box {
  height: 100vh;
  background-color: #FAFAFA;
}
</style>