import { createApp } from 'vue'
import App from './App.vue'
import { createPinia } from "pinia";
// 创建pinia实例
const pinia = createPinia()
import router from './router/router.js'
import 'element-plus/dist/index.css';
import ElementPlus from 'element-plus'
import 'highlight.js/styles/atom-one-dark.css'
import 'highlight.js/lib/common'
import hljsVuePlugin from '@highlightjs/vue-plugin'

//创建v-highlight全局指令
// Vue.directive('highlight',function (el) {
// 	let blocks = el.querySelectorAll('pre code');
// 	blocks.forEach((block)=>{
// 	  hljs.highlightBlock(block)
// 	})
//   })
  
createApp(App)
	.use(router)
	.use(hljsVuePlugin)
	.use(pinia)
	.mount('#app')
