import axios from 'axios';
import env from '@/utils/env.js'
import { globalData } from '@/store/store';
// 设置接口超时时间
axios.defaults.timeout = 60000;

// 请求地址，这里是动态赋值的的环境变量，下一篇会细讲，这里跳过
axios.defaults.baseURL = env.baseURL;

//http request 拦截器
axios.interceptors.request.use(
  config => {
    // 配置请求头
    config.headers = {
      //'Content-Type':'application/x-www-form-urlencoded',   // 传参方式表单
      'Content-Type': 'application/json;charset=UTF-8',        // 传参方式json
      'token': localStorage.token              // 这里自定义配置，这里传的是token
    };
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

//http response 拦截器
axios.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    const { response } = error;
    if (response) {
      // 请求已发出，但是不在2xx的范围
      return Promise.reject(response.data);
    } else {
      ElMessage.warning('网络连接异常,请稍后再试!');
    }
  }
);

// 封装 GET POST 请求并导出
export function request(paramsObj) {
  let { url, data, method, toLogin = true, showErrorMsg = true } = paramsObj;
  let store = globalData();
  if (!url) {
    return console.error('请传入请求地址 url')
  }
  let params = data || {};
  let type = method || 'get';
  //设置 url params type 的默认值
  return new Promise((resolve, reject) => {
    let promise
    if (type.toUpperCase() === 'GET') {
      promise = axios({
        url,
        params
      })
    } else {
      promise = axios({
        method: type.toUpperCase(),
        url,
        data: params
      })
    }
    //处理返回
    promise.then(res => {
      store.hideLoading()
      if (res.data && res.data.msg === 'success') {
        resolve(res.data)
      } else if (res.data.code === 8000) {
        store.setToken('')
        if (!toLogin) {
          return;
        }
        //掉线了 去login页面
        store.setLoginVisible(true);
      } else {
        reject(res)
        if (showErrorMsg) {
          ElMessage.error(res.data.msg || '网络错误');
        }
      }
    }).catch(err => {
      reject(err)
    })
  })
}