import { dateFormatter } from "@/utils/utils";

export const DEFAULT_SESSION = {
  formatTitle: '新的聊天',
  id: 0,
  updateDate: dateFormatter.format(new Date()),
  isDefault: true,
  questionCount: 0
}

export const searchBtnStyle = {
  backgroundColor: '#63acb6',
  borderRadius: '10rpx',
  height: '75rpx',
  verticalAlign: 'center',
  lineHeight: '75rpx',
  color: '#ffffff',
  width: '60px'
}

export const AIS_LOGO_URL = 'https://netefox.oss-cn-shenzhen.aliyuncs.com/efox-chat-gpt/answers.png'

export const USER_AVATAR = {
  nickName: '小助手',
  avatar: AIS_LOGO_URL,
}

export const OUT_OF_MONEY = '提问次数已用完,可通过邀请获得次数,或购买次数'

export const TEMPLATE_FORM = {
  qsCall: '',
  qsHint: '',
  qsTitle: '',
  tid: ''
}

export const ACTIVE_MSG = `您当前账户未激活,您可通过以下<b>任一方式</b>进行激活，激活后可获取每天100次免费提问和100张免费绘图。:<br>1、邀请5名成员获取，<a href="${window.location.origin}/home/user">点击获取分享链接</a>(跳转后点击“邀请链接”即可获取);<br>2、<a href="${window.location.origin}/home/recharge">升级VIP会员</a>;<br>3、抖音搜索“八戒学ai”，点击任一视频进入评论区，点击加入粉丝群领取激活码。<br><br><i>关闭后可以点击头部菜单的“输入激活码”再次打开输入。</i>`

export const ERROR_CODES = ['10113', '10115']