<template>
  <!-- 卡密兑换 -->
  <el-dialog v-model="kamiVisible" :center="true" width="360px" :close-on-click-modal="false" class="dialog-mask"
    title="卡密兑换">
    <div class="px-20">
      <div class="form-box">
        <el-input placeholder="请输入卡密" v-model="kamiValue"></el-input>
      </div>
      <div class="form-footer mt-20 flex flex-center">
        <el-button style="width:100%;" size="large" type="primary" @click="kamiSubmit">确认兑换</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { CamilleExchange } from '@/http/http.js';
export default {
  name: 'conpon',
  data() {
    return {
      kamiVisible: false,
      kamiValue: '',
    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    open() {
      this.kamiValue = '';
      this.kamiVisible = true;
    },
    kamiSubmit() {
      if (!this.kamiValue) {
        return ElMessage.error('卡密不能为空')
      }
      CamilleExchange({ "camilleNumber": this.kamiValue }).then(res => {
        ElMessage.success('兑换成功');
        this.kamiVisible = false;
      })

    },
  },
}
</script>

<style scoped lang="scss"></style>